    <div id="sidebar-wrapper sidebar-accordion" class="sidebar-wrapper accordion" *ngIf="isUserLoggedIn">
      <ul class="sidebar-nav accordion" id="sidebar-accordion" *ngIf="!isDistrictUser && !isFoundationsUser && !isSchoolUser">
          <li *ngIf="canViewEnrollSection" class="accordion-item">
            <h2 class="accordion-header" id="headingEnroll">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEnroll" aria-expanded="true" aria-controls="collapseEnroll">
                <fa-icon [icon]="enrollIcon"></fa-icon> Enroll
              </button>
            </h2>
            <div id="collapseEnroll" class="accordion-collapse collapse show" aria-labelledby="headingEnroll" data-bs-parent="#sidebar-accordion">
              <ul>
                <li class="margin-top-10">
                  <div class="sidebar-subheader">Students</div>
                  <div *ngIf="isCustomerServiceUser">
                    <a id="batchenrollment" class="sidebar-item" routerLink='/batch-enrollment' routerLinkActive="sidebar-item-focus">
                      Batch Enrollment
                    </a>
                  </div>
                  <div *ngIf="canViewEnroll">
                    <a id="studentlist" class="sidebar-item" routerLink='/students' routerLinkActive="sidebar-item-focus" [routerLinkActiveOptions]="{ exact: true }">
                      List
                    </a>
                  </div>
                </li>
                <li class="margin-top-10" *ngIf="canManageSubscriptions">
                  <div class="sidebar-subheader">Subscriptions</div>
                  <div>
                    <a id="assigndiagnostic" class="sidebar-item" routerLink='/students/manage-subscriptions' routerLinkActive="sidebar-item-focus">
                      Manage
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li *ngIf="!isCustomerServiceUser" class="accordion-item">
            <h2 class="accordion-header" id="headingReports">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseReports" aria-expanded="false" aria-controls="collapseReports">
                <fa-icon [icon]="detailedReportsIcon"></fa-icon> Reports
              </button>
            </h2>
            <ul>
              <div id="collapseReports" class="accordion-collapse collapse" aria-labelledby="headingReports" data-bs-parent="#sidebar-accordion">
                <ul>
                  <li class="margin-top-10" *ngIf="canViewScreenerReports">
                    <div class="sidebar-subheader">{{ freeProduct }}</div>
                    <div id="screenerresults">
                      <a class="sidebar-item" routerLink='/reports/screener' routerLinkActive="sidebar-item-focus">Results</a>
                    </div>
                  </li>
                  <li class="margin-top-10" *ngIf="canViewDiagnosticReports">
                    <div class="sidebar-subheader">{{ diagnosticProduct }}</div>
                    <div id="diagnosticresults">
                      <a class="sidebar-item" routerLink='/reports/diagnostic/diagnostic' routerLinkActive="sidebar-item-focus">Results</a>
                    </div>
                  </li>
                  <li class="margin-top-10" *ngIf="canViewSystemReports">
                    <div class="sidebar-subheader">{{ fullProduct }}</div>
                    <div id="dashboardAC" *ngIf="canViewSystemDashboardReport">
                      <a routerLink="/dashboard/system" class="sidebar-item" routerLinkActive="sidebar-item-focus">Dashboard</a>
                    </div>
                    <div id="dashboardAC" *ngIf="canViewHomeDashboardReport">
                      <a routerLink="/dashboard/home" class="sidebar-item" routerLinkActive="'sidebar-item-focus">Dashboard</a>
                    </div>
                    <div id="studentsummary">
                      <a routerLink="/reports/system/summary/student" class="sidebar-item" routerLinkActive="sidebar-item-focus">Student Summary</a>
                    </div>
                    <div id="diagnosticresultsAC" *ngIf="canViewHomeSystemDiagnosticReport">
                      <a routerLink="/reports/system/diagnostic" class="sidebar-item" routerLinkActive="sidebar-item-focus">Diagnostic Results</a>
                    </div>
                    <ng-container *ngIf="canViewAllOtherSystemReports">
                      <div id="groupsummary">
                        <a routerLink="/reports/system/summary/group" class="sidebar-item" routerLinkActive="sidebar-item-focus">Group Summary</a>
                      </div>
                      <div id="objectivepre-post">
                        <a routerLink="/reports/system/objective" class="sidebar-item" routerLinkActive="sidebar-item-focus">Objective Pre/Post</a>
                      </div>
                      <div id="unitdetail">
                        <a routerLink="/reports/system/unit-detail" class="sidebar-item" routerLinkActive="sidebar-item-focus">Unit Detail</a>
                      </div>
                      <div id="diagnosticresultsAC">
                        <a routerLink="/reports/system/diagnostic" class="sidebar-item" routerLinkActive="sidebar-item-focus">Diagnostic Results</a>
                      </div>
                      <div id="sessionsummary">
                        <a routerLink="/reports/system/summary/session" class="sidebar-item" routerLinkActive="sidebar-item-focus">Session Summary</a>
                      </div>
                    </ng-container>
                  </li>
                </ul>
              </div>
            </ul>
          </li>

          <li class="accordion-item" *ngIf="canViewResources">
            <h2 class="accordion-header" id="headingResources">
              <button class="accordion-button collapsed sidebar-brand" type="button" data-bs-toggle="collapse" data-bs-target="#collapseResources" aria-expanded="false" aria-controls="collapseResources">
                <fa-icon [icon]="resourcesIcon"></fa-icon> Resources
              </button>
            </h2>
            <div id="collapseResources" class="accordion-collapse collapse accordion-item" aria-labelledby="headingResources" data-bs-parent="#sidebar-accordion">
              <ul>
                <li *ngIf="canViewSystemResources" class="margin-top-10">
                  <div class="sidebar-subheader">Instructional Tools</div>
                  <div id="lesson-generator" *ngIf="canViewLessonGenerator">
                    <a class="sidebar-item" routerLink="/resources/generator" routerLinkActive="sidebar-item-focus">Create Lesson Plan</a>
                  </div>
                  <div id="grades-2-4">
                    <a class="sidebar-item" routerLink="/resources/system/list/elementary" routerLinkActive="sidebar-item-focus">Grades 2 - 4</a>
                  </div>
                  <div id="grades-5-up">
                    <a class="sidebar-item" routerLink="/resources/system/list/secondary" routerLinkActive="sidebar-item-focus">Grades 5+</a>
                  </div>
                  <div id="model-lessons">
                    <a class="sidebar-item" routerLink="/resources/system/all/model-lessons" routerLinkActive="sidebar-item-focus">Small Group Lessons</a>
                  </div>
                </li>
                <li class="margin-top-10">
                  <div class="sidebar-subheader">Knowledge Base</div>
                  <div id="screenerresouces">
                    <a routerLink="/resources/screener/" class="sidebar-item" routerLinkActive="sidebar-item-focus">{{freeProduct}}</a>
                  </div>
                  <div id="user-guides">
                    <a class="sidebar-item" routerLink="/resources/system/all/User Guides" routerLinkActive="sidebar-item-focus">User Guides</a>
                  </div>
                  <div id="videos">
                    <a class="sidebar-item" routerLink="/resources/videos" routerLinkActive="sidebar-item-focus">Professional Learning</a>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li *ngIf="!isCustomerServiceUser" class="accordion-item">
            <h2 class="accordion-header" id="headingSupport">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSupport" aria-expanded="false" aria-controls="collapseSupport">
                <fa-icon [icon]="supportIcon"></fa-icon> Support
              </button>
            </h2>
            <div id="collapseSupport" class="accordion-collapse collapse accordion-item" aria-labelledby="headingSupport" data-bs-parent="#sidebar-accordion">
              <ul>
                <li class="margin-top-10">
                  <div class="sidebar-subheader">Support</div>
                  <div>
                    <a class="sidebar-item" routerLink="/support/contact" routerLinkActive="sidebar-item-focus">Contact</a>
                  </div>
                </li>
                <li class="margin-top-10" *ngIf="userRole !== 'ROLE_TEACHER_REVIEW'">
                  <div class="sidebar-subheader">Feedback</div>
                  <div>
                    <a class="sidebar-item" routerLink="/survey" routerLinkActive="sidebar-item-focus">Surveys</a>
                  </div>
                </li>
              </ul>
            </div>
          </li>
      </ul>

      <!-- FIL Reports User / District Reports User -->
      <ul class="sidebar-nav foundations-user accordion" id="sidebar-accordion" *ngIf="isFoundationsUser || isDistrictUser || isSchoolUser">

        <!-- District Level Reports -->
        <li class="accordion-item">
          <h2 class="accordion-header" id="headingDistrict">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDistrict" aria-expanded="true" aria-controls="collapseDistrict">
              <fa-icon [icon]="districtReportsIcon"></fa-icon> {{ isSchoolUser ? ' School' : ' District' }} Reports
            </button>
          </h2>
          <div id="collapseDistrict" class="accordion-collapse collapse show accordion-item" aria-labelledby="headingDistrict" data-bs-parent="#sidebar-accordion">
            <ul>
              <li class="margin-top-10">
                <div class="sidebar-subheader">WordFlight {{ freeProduct }}</div>
                <div id="screenerresults" *ngIf="canViewScreenerReports">
                  <a routerLink="/reports/district/screener" routerLinkActive="sidebar-item-focus" class="sidebar-item">{{ freeProduct }}</a>
                </div>
              </li>
              <li class="margin-top-10">
                <div class="sidebar-subheader">WordFlight {{ fullProduct }}</div>
                <div>
                  <a routerLink="/reports/district/usage" routerLinkActive="sidebar-item-focus" class="sidebar-item">Usage</a>
                </div>
                <div>
                  <a routerLink="/reports/district/progress" routerLinkActive="sidebar-item-focus" class="sidebar-item">Unit Progress</a>
                </div>
              </li>
              <li class="margin-top-10">
                <div class="sidebar-subheader">WordFlight {{ diagnosticProduct }}</div>
                <div>
                  <a routerLink="/reports/district/diagnostic" routerLinkActive="sidebar-item-focus" class="sidebar-item">{{ diagnosticProduct }}</a>
                </div>
              </li>
            </ul>
          </div>
        </li>

        <!-- Detailed Reports -->
        <li class="accordion-item">
          <h2 class="accordion-header" id="headingDetailed">
            <button class="accordion-button collapsed sidebar-brand" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDetailed" aria-expanded="false" aria-controls="collapseDetailed">
              <fa-icon [icon]="detailedReportsIcon"></fa-icon> Detailed Reports
            </button>
          </h2>
          <div id="collapseDetailed" class="accordion-collapse collapse accordion-item" aria-labelledby="headingDetailed" data-bs-parent="#sidebar-accordion">
            <ul>
              <li *ngIf="canViewScreenerReports" class="margin-top-10">
                <div class="sidebar-subheader">{{ freeProduct }}</div>
                <div>
                  <a id="screenerresults" routerLink='/reports/screener' routerLinkActive="sidebar-item-focus" class="sidebar-item">
                    Results
                  </a>
                </div>
              </li>
              <li *ngIf="canViewDiagnosticReports" class="margin-top-10">
                <div class="sidebar-subheader">{{ diagnosticProduct }}</div>
                <div>
                  <a id="diagnosticresults" class="sidebar-item" routerLink='/reports/diagnostic/diagnostic' routerLinkActive="sidebar-item-focus">
                    Results
                  </a>
                </div>
              </li>
              <li *ngIf="canViewSystemReports" class="margin-top-10">
                <div class="sidebar-subheader">{{ fullProduct }}</div>
                <div id="dashboardAC" *ngIf="canViewSystemDashboardReport">
                  <a routerLink="/dashboard/system" routerLinkActive="sidebar-item-focus" class="sidebar-item">Dashboard</a>
                </div>
                <div id="dashboardAC" *ngIf="canViewHomeDashboardReport">
                  <a routerLink="/dashboard/home" routerLinkActive="'sidebar-item-focus" class="sidebar-item">Dashboard</a>
                </div>
                <div id="studentsummary">
                  <a routerLink="/reports/system/summary/student" class="sidebar-item" routerLinkActive="sidebar-item-focus">Student Summary</a>
                </div>
                <div id="diagnosticresultsAC" *ngIf="canViewHomeSystemDiagnosticReport">
                  <a routerLink="/reports/system/diagnostic" routerLinkActive="sidebar-item-focus" class="sidebar-item">Diagnostic Results</a>
                </div>
              </li>
              <li *ngIf="canViewAllOtherSystemReports">
                <div id="groupsummary">
                  <a routerLink="/reports/system/summary/group" routerLinkActive="sidebar-item-focus" class="sidebar-item" >Group Summary</a>
                </div>
                <div id="objectivepre-post">
                  <a routerLink="/reports/system/objective" routerLinkActive="sidebar-item-focus" class="sidebar-item" >Objective Pre/Post</a>
                </div>
                <div id="unitdetail">
                  <a routerLink="/reports/system/unit-detail" routerLinkActive="sidebar-item-focus" class="sidebar-item" >Unit Detail</a>
                </div>
                <div id="diagnosticresultsAC">
                  <a routerLink="/reports/system/diagnostic" routerLinkActive="sidebar-item-focus" class="sidebar-item" >Diagnostic Results</a>
                </div>
                <div id="sessionsummary">
                  <a routerLink="/reports/system/summary/session" routerLinkActive="sidebar-item-focus" class="sidebar-item" >Session Summary</a>
                </div>
              </li>
            </ul>
          </div>
        </li>

        <li class="accordion-item" *ngIf="canViewResources">
          <h2 class="accordion-header" id="headingResources">
            <button class="accordion-button collapsed sidebar-brand" type="button" data-bs-toggle="collapse" data-bs-target="#collapseResources" aria-expanded="false" aria-controls="collapseResources">
              <fa-icon [icon]="resourcesIcon"></fa-icon> Resources
            </button>
          </h2>
          <div id="collapseResources" class="accordion-collapse collapse accordion-item" aria-labelledby="headingResources" data-bs-parent="#sidebar-accordion">
            <ul>
              <li *ngIf="canViewSystemResources" class="margin-top-10">
                <div class="sidebar-subheader">Instructional Tools</div>
                <div id="lesson-generator" *ngIf="canViewLessonGenerator">
                  <a class="sidebar-item" routerLink="/resources/generator" routerLinkActive="sidebar-item-focus">Create Lesson Plan</a>
                </div>
                <div id="grades-2-4">
                  <a class="sidebar-item" routerLink="/resources/system/list/elementary" routerLinkActive="sidebar-item-focus">Grades 2 - 4</a>
                </div>
                <div id="grades-5-up">
                  <a class="sidebar-item" routerLink="/resources/system/list/secondary" routerLinkActive="sidebar-item-focus">Grades 5+</a>
                </div>
                <div id="model-lessons">
                  <a class="sidebar-item" routerLink="/resources/system/all/model-lessons" routerLinkActive="sidebar-item-focus">Small Group Lessons</a>
                </div>
              </li>
              <li class="margin-top-10">
                <div class="sidebar-subheader">Knowledge Base</div>
                <div id="screenerresouces">
                  <a routerLink="/resources/screener/" class="sidebar-item" routerLinkActive="sidebar-item-focus">{{freeProduct}}</a>
                </div>
                <div id="user-guides">
                  <a class="sidebar-item" routerLink="/resources/system/all/User Guides" routerLinkActive="sidebar-item-focus">User Guides</a>
                </div>
                <div id="videos">
                  <a class="sidebar-item" routerLink="/resources/videos" routerLinkActive="sidebar-item-focus">Professional Learning</a>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li *ngIf="!isFoundationsUser" class="accordion-item">
          <h2 class="accordion-header" id="headingSupport">
            <button class="accordion-button collapsed sidebar-brand" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSupport" aria-expanded="false" aria-controls="collapseSupport">
              <fa-icon [icon]="supportIcon"></fa-icon> Support
            </button>
          </h2>
          <div id="collapseSupport" class="accordion-collapse collapse accordion-item" aria-labelledby="headingSupport" data-bs-parent="#sidebar-accordion">
            <ul>
              <li class="margin-top-10">
                <div class="sidebar-subheader">Support</div>
                <div>
                  <a class="sidebar-item" routerLink="/support/contact" routerLinkActive="sidebar-item-focus">Contact</a>
                </div>
              </li>
              <li class="margin-top-10">
                <div class="sidebar-subheader">Feedback</div>
                <div>
                  <a class="sidebar-item" routerLink="/survey" routerLinkActive="sidebar-item-focus">Surveys</a>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
  </div>
